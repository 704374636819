import React, { useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { v4 as uuidv4 } from 'uuid'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Tagline from "../components/tagline"
import Promo from "../components/promo-text"
import ContactUs from "../components/contact-us"
import Episode from "../components/episode"

function EarlierEpisodes() {
  let data = useStaticQuery(
    graphql`
    query {
        allEpisode(skip: 10) {
          nodes {
            embedId
            episodeNumber
            pubDate
            description
          }
        }
      }
    `
  )

  let episodes = data.allEpisode.nodes

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
        <SEO title="Previous Episodes" />
        <Tagline content="Earlier Episodes"/>
        <Promo color="light">
          {episodes.map((episode) => {
                      return (
                          <Episode
                              key={uuidv4()}
                              embedId={episode.embedId}
                              episodeNumber={episode.episodeNumber}
                              description={episode.description}
                          />
                      )
                  })
          }
        </Promo >
        <ContactUs />
    </Layout>

  )
}

export default EarlierEpisodes
